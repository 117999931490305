export default {
    header: `Kuolleet aistinkarvat`,
    introduction: `Pilvi kokeilee selvittämäänsä koodia oven lukkoon ja ovi aukeaa. Hän kaappaa kaupungin kartan mukaansa ja lähtee ulos. Luonto mökin ympärillä on todella hiljainen. Samassa mieleen muistuu illan konsertti.

    Pilvi miettii, miten hän voisi suojautua voimakkaalta musiikilta ja yleisön ääniltä. Löytyisiköhän piharakennuksesta kuulonsuojaimet, jotka hän voisi ottaa mukaansa? Hän katselee ympärilleen. Lapio, vasara, laatikollinen nauloja, katkennut poranterä … ja sitten, pahvilaatikko, joka on täynnä erilaisia kuulonsuojaimia. Pitäisi vielä tietää, mitkä näistä sopivat mihinkin tilanteeseen. 
    
    Tutustu alla oleviin kuulonsuojaustapoihin klikkaamalla kuvaa ja lukemalla siitä kertova teksti.
    
    Valitse lopuksi sopivimmat tavat suojata kuuloa illan konserttiin. Tässä tehtävässä voi olla useampi oikea vastaus.`,
};
