import { useState } from 'react';

import { TextField } from '@mui/material';

import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/title';
import Text from '../../components/text';

import image1 from '../../res/exercise2/1.png';

import listening from '../../texts/listening';
import styled from 'styled-components';

const sound = require('../../res/audio/3_Hiljainen-puisto.mp3');

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;

    text-align: center;
    overflow-y: auto;
`;

function Listening() {
    const [answer, setAnswer] = useState<string>('');
    return (
        <PageWrapper
            nextPageURL="/korvan-anatomia"
            deathMessage="Maatila"
            canContinue={() =>
                answer.toLowerCase() === listening.answer.toLowerCase()
            }
        >
            <ContentWrapper>
                <audio src={sound} controls></audio>
                <Title>{listening.header}</Title>
                <Text>{listening.introduction}</Text>
                <img
                    style={{ width: '95%', margin: '0px 2% 0px 2%' }}
                    src={image1}
                    alt=""
                />
                <Text>{listening.instructions}</Text>
                <TextField
                    InputProps={{
                        style: { backgroundColor: 'white', color: 'black' },
                    }}
                    value={answer}
                    onChange={(e) => {
                        setAnswer(e.target.value);
                    }}
                    variant="outlined"
                    label="Vastaus"
                    style={{
                        margin: '20px 2% 20px 2%',
                    }}
                />
            </ContentWrapper>
        </PageWrapper>
    );
}

export default Listening;
