export default {
    header: `Melumysteeri`,
    intorduction: `Pilvi on 15-vuotias peruskoulun viimeistä luokkaa käyvä tyttö, joka asuu suomalaisessa pikkukaupungissa.

    Kaupunki on saanut suuren rahalahjoituksen, jolla on tarkoitus parantaa kaupungin ilmettä. Katujen alla olevat vanhat vesi- ja viemärilinjat uusitaan ja katuja joudutaan avaamaan. Puisto-osastolle on hankittu tehokkaita lehtipuhaltimia ja päältä ajettava ruohonleikkuri.
    
    Suuret kaivinkoneet, katuporat, ruohonleikkurit ja lehtipuhaltimet ovat meluisia, puhumattakaan uuden hälytyssireenin kimeästä ujelluksesta. Se on vanhaa sireeniä huomattavasti kovempi ja kuuluu naapuripitäjään saakka.
    
    Auta pilviä ratkaisemaan melumysteerit, jotta hän voi rauhassa keskittyä koulutehtäviinsä.
    
    Vastausyrityksiä on viisi, jonka jälkeen peli päästää seuraavaan tehtävään.  Kokeile rohkeasti eri vaihtoehtoja.  Joissakin tehtävissä saat kolmannen yrityskerran jälkeen vihjeitä ratkaisuun.`,
};
