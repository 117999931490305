import React from 'react';
import { useState } from 'react';
import { Button } from '@mui/material';

import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/title';
import Text from '../../components/text';
import styled, { css } from 'styled-components';
import earAnatomyText from '../../texts/earAnatomy';

import korvalehti from '../../res/earAnatomy/korvalehti.svg';
import tarykalvo from '../../res/earAnatomy/tarykalvo.svg';
import vasara from '../../res/earAnatomy/vasara.svg';
import alasin from '../../res/earAnatomy/alasin.svg';
import jalustin from '../../res/earAnatomy/jalustin.svg';
import simpukka from '../../res/earAnatomy/simpukka.svg';

const sound = require('../../res/audio/4_Mokki-pellon-takana.mp3');

const ImagesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`;

const outline = css`
    box-sizing: border-box;
    border: 5px solid #33ff33;
`;

const StyledImage = styled.img<{ border?: boolean }>`
    justify-self: center;
    width: 200px;
    height: 136.217px;
    margin: 20px;
    cursor: pointer;
    ${({ border }) => border && outline}
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const EarAnatomy = () => {
    const images = [vasara, tarykalvo, korvalehti, simpukka, jalustin, alasin];
    const correctOrder = [2, 1, 0, 5, 4, 3];
    const [clicks, setClicks] = useState<Array<number>>([]);

    const imageClicked = (imageIndex: number) => {
        if (clicks.includes(imageIndex)) {
            return;
        }
        setClicks([...clicks, imageIndex]);
    };

    const isCorrectSolution = () => {
        const isCorrect =
            JSON.stringify(clicks) === JSON.stringify(correctOrder);
        setClicks([]);
        return isCorrect;
    };

    const clearSelection = () => {
        setClicks([]);
    };

    return (
        <PageWrapper
            nextPageURL="/meluvideot"
            deathMessage="Ääni etenee korvalehdestä vasaraan joka lyö alasimeen, jonka kautta se kulkeutuu jalustimeen ja viimeisenä simpukkaan."
            canContinue={isCorrectSolution}
        >
            <ContentWrapper>
                <audio src={sound} controls></audio>
                <Title>{earAnatomyText.header}</Title>
                <Text>{earAnatomyText.introduction}</Text>
                <Text>{earAnatomyText.task}</Text>
                <ImagesContainer>
                    {images.map((image, index) => (
                        <StyledImage
                            src={image}
                            key={`${image}-${index}`}
                            alt=""
                            onClick={() => imageClicked(index)}
                            border={clicks.includes(index)}
                        />
                    ))}
                </ImagesContainer>
                <Button
                    color="error"
                    size="small"
                    style={{
                        fontSize: 30,
                        alignSelf: 'center',
                        width: 'fit-content',
                    }}
                    variant="contained"
                    onClick={clearSelection}
                >
                    Nollaa
                </Button>
                <Text>{earAnatomyText.instructions}</Text>
            </ContentWrapper>
        </PageWrapper>
    );
};

export default EarAnatomy;
