import styled from 'styled-components';

const Title = styled.h2`
    font-size: 1.9em;
    margin: 20px;
    margin-left: 2%;
    text-align: left;
    color: #eae8ed;
`;

export default Title;
