import styled from 'styled-components';
import tausta from '../res/kuvitus2.png';

const ContentWrapper = styled.div`
    background-size: 100%;
    background-repeat: repeat-y;
    background-position: center;
    background-clip: padding-box;
    background-image: url(${tausta});
    background-size: 100%;
    display: flex;
    flex-direction: column;
    width: 100vw;
    flex-grow: 1;
    text-align: left;
`;

export default ContentWrapper;
