import { useState } from 'react';
import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/title';
import Text from '../../components/text';
import deadSensorhairsText from '../../texts/deadsensorhairs';
import styled, { css } from 'styled-components';

import kupusuojaimet from '../../res/deadSensorshairs/kupusuojaimet.png';
import korvatulpat from '../../res/deadSensorshairs/korvatulpat.png';
import hifitulpat from '../../res/deadSensorshairs/hifitulpat.png';
import yksilollisetkuulosuojaimet from '../../res/deadSensorshairs/yksilollisetkuulosuojaimet.png';
import tauko from '../../res/deadSensorshairs/tauko.png';

const sound = require('../../res/audio/6_Kuolleet-aistinkarvat.mp3');

const outline = css`
    box-sizing: border-box;
    border: 3px solid #33ff33;
`;

const StyledImage = styled.img<{ border?: boolean }>` //earmuff
    justify-self: center;
    width 100px;
    height: 135.217px;
    margin: 4%;
    margin-left: 0;
    margin-top: 0;
    cursor: pointer;
    ${({ border }) => border && outline}
`;

const TextContainer = styled.div`
    display: flex;
    max-width: 85vw;
    width: 460px;
    background-color: white;
    margin: 2%;
`;

const EarmuffContainer = styled.div`
    width: 460px;
    max-width: 80vw;
    height: 67%;
    margin-left: 2%;
`;

const VisualContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

let imageFirst = 0;

const DeadSensorhairs = () => {
    const earmuffImages = [
        korvatulpat,
        kupusuojaimet,
        hifitulpat,
        yksilollisetkuulosuojaimet,
        tauko,
    ];

    const text1 =
        'Vaahtomuoviset korvatulpat ovat edullisimmat ja yleisimmin käytetyt \
        kuulonsuojaimet. Ne soveltuvat hyvin satunnaiseen käyttöön esimerkiksi \
        konsertissa. Vaimennustehoon vaikuttaa se, kuinka huolellisesti tulppa on \
        asetettu korvakäytävään.';

    const text2 =
        'Kupusuojaimet soveltuvat hyvin esimerkiksi metsästykseen ja koneita tai laitteita \
        käytettäessä. Suojaimet vaimentavat haitallista melua, mutta päästävät puhetaajuudet läpi.';

    const text3 =
        'Hifi-tulpat voi pestä, ja niitä voi käyttää monta kertaa, mikä tekee niistä \
        edullisen vaihtoehdon. Kaikkia taajuuksia tasaisesti vaimentavina ne soveltuvat\
        hyvin musiikin kuunteluun, eivätkä muuta musiikin sävyä.';

    const text4 =
        'Yksilölliset kuulonsuojaimet tehdään käyttäjän korvasta otetun mallin \
        mukaisesti. Ne ovat tiiviit ja mukavat käyttää. Erilaisilla suodattimilla \
        voidaan vaikuttaa vaimennuksen tehoon. Vaimennus saadaan tasaiseksi kaikilla \
        taajuuksilla. Yksilölliset suojaimet sopivat hyvin muusikoille ja musiikin \
        harrastajille.';

    const text5 =
        'Tauon pitäminen kovista äänistä suojaa kuuloa antamalla korville \
        mahdollisuuden toipua pitkäaikaisen äänen aiheuttamasta rasituksesta.';

    const visualImages = [text1, text2, text3, text4, text5];

    const [clicks, setClicks] = useState<Array<number>>([0]);
    const [current, setCurrent] = useState(0);

    const isCorrectSolution = () => {
        return current === 0 || current === 2 || current === 3;
    };

    const imageClicked = (imageIndex: number) => {
        setCurrent(imageIndex);
        setClicks([imageIndex]);
        imageFirst = imageIndex;
    };

    const ContentWrapper = styled.div`
        display: flex;
        flex-direction: column;
    `;

    return (
        <PageWrapper
            nextPageURL="/kuulon-suojaaminen"
            deathMessage="Hifi-tulpat ja yksilölliset suojaimet antavat hyvän äänenlaadun konsertin kuunteluun."
            canContinue={isCorrectSolution}
        >
            <ContentWrapper>
                <audio src={sound} controls></audio>
                <Title>{deadSensorhairsText.header}</Title>
                <Text>{deadSensorhairsText.introduction}</Text>
                <Title>Tehtävä</Title>
            </ContentWrapper>
            <TextContainer>
                <h2 style={{ margin: 0, padding: '10px' }}>
                    {visualImages[imageFirst]}
                </h2>
            </TextContainer>
            <EarmuffContainer>
                {earmuffImages.map((image, index) => (
                    <StyledImage
                        src={image}
                        key={`${image}-${index}`}
                        alt=""
                        onClick={() => imageClicked(index)}
                        border={clicks.includes(index)}
                    />
                ))}
            </EarmuffContainer>
        </PageWrapper>
    );
};

export default DeadSensorhairs;
