import { useState } from 'react';
import styled from 'styled-components';

import PageWrapper from '../../components/PageWrapper';
import Text from '../../components/text';
import Title from '../../components/title';
import hearingClaims from '../../texts/hearingClaims';

const sound = require('../../res/audio/8_Biologian-koe.mp3');

const QuestionsWrapper = styled.div`
    padding: 1%;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const QuestionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const QuestionText = styled(Text)`
    width: 70%;
`;

const AsnwerText = styled(Text)`
    margin: 0;
`;

const AnswerContainer = styled.div`
    margin-right: 11%;
    width: 10%;
    display: flex;
    justify-content: space-around;
    gap: 20px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
`;

const HearingClaims = () => {
    const claims = [
        'Kuulo on ihmisen tärkein aisti.',
        'Kuulo heikkenee jokaisella jossain vaiheessa, eikä siihen voi vaikuttaa.',
        'Melun aiheuttaman kuulovaurion parantamiseen ei ole keksitty lääketieteellistä hoitokeinoa.',
        'Puheen erotteleminen vaikeutuu hälyisässä tilassa.',
        'Ilman kuuloa ei voi elää täysipainoista elämää.',
        'Kaikilla yli 75-vuotiailla on kuulo heikentynyt ja se on osa ikääntymistä.',
        'Huonokuuloisen ei tarvitse suojata kuuloaan.',
        'Tasapainoelin sijaitsee korvassa.',
        'Korvakäytävän iho on vahvaa.',
    ];

    const correctAnswers = [
        false,
        false,
        true,
        true,
        false,
        false,
        false,
        true,
        false,
    ];

    const [answers, setAnswers] = useState<Array<boolean | undefined>>(
        claims.map(() => undefined)
    );

    const tarkista = () => {
        let newArr = [...answers];
        if (JSON.stringify(answers) === JSON.stringify(correctAnswers)) {
            return true;
        }
        for (let i = 0; i <= 8; i++) {
            if (answers[i] === correctAnswers[i]) {
                continue;
            } else {
                newArr[i] = undefined;
            }
        }
        setAnswers(newArr);
        return false;
    };

    return (
        <PageWrapper nextPageURL="/lopetus" canContinue={tarkista}>
            <ContentWrapper>
                <audio src={sound} controls></audio>
                <Title>{hearingClaims.header}</Title>
                <Text>{hearingClaims.introduction}</Text>
                <Text>{hearingClaims.instruction}</Text>
            </ContentWrapper>
            <QuestionsWrapper>
                <QuestionContainer>
                    <div></div>
                    <AnswerContainer>
                        <AsnwerText>Oikein</AsnwerText>
                        <AsnwerText>Väärin</AsnwerText>
                    </AnswerContainer>
                </QuestionContainer>
                {claims.map((claim, index) => (
                    <QuestionContainer key={claim}>
                        <QuestionText>{claim}</QuestionText>
                        <AnswerContainer>
                            <input
                                type="checkbox"
                                checked={answers[index]}
                                onClick={(e) => {
                                    const newAnswers = [...answers];
                                    newAnswers[index] = true;
                                    setAnswers(newAnswers);
                                }}
                            />
                            <input
                                type="checkbox"
                                checked={
                                    answers[index] !== undefined &&
                                    !answers[index]
                                }
                                onClick={(e) => {
                                    const newAnswers = [...answers];
                                    newAnswers[index] = false;
                                    setAnswers(newAnswers);
                                }}
                            />
                        </AnswerContainer>
                    </QuestionContainer>
                ))}
            </QuestionsWrapper>
        </PageWrapper>
    );
};

export default HearingClaims;
