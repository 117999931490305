import { Link } from 'react-router-dom';

import PageWrapper from '../components/FirstPageWrapper';
import ContentWrapper from '../components/contentWrapper';
import Title from '../components/title';
import Footer from '../components/footer';
import Text from '../components/text';
import { Button } from '@mui/material';

import home from '../texts/home';
const sound = require('../res/audio/1_melumysteeri_vihje.mp3');

function Home() {
    return (
        <PageWrapper>
            <ContentWrapper>
                <audio src={sound} controls></audio>
                <Title>{home.header}</Title>
                <Text>{home.intorduction}</Text>
            </ContentWrapper>
            <Footer>
                <Link to={'/piilosana'}>
                    <Button
                        variant="contained"
                        style={{
                            margin: '10px 2% 10px 2%',
                            backgroundColor: '#ffe313',
                            color: '#000000',
                        }}
                    >
                        Tästä tehtäviin
                    </Button>
                </Link>
            </Footer>
        </PageWrapper>
    );
}

export default Home;
