export default {
    header: `Mystinen kirje`,
    introduction: `Pilvi istuu huoneessaan ja katselee epätoivoisesti biologian kirjaansa. Huomenna on koe ihmisen anatomiasta, eikä pänttääminen juuri nyt suju. Ulkoa kuuluu liikenteen ja työkoneiden melua, naapuritontilla lehtipuhallin ulvoo ja jossain pauhaa radio. Pilvi tuijottaa korvan anatomiaa esittävää kuvaa ja toistelee mielessään korvan osien nimiä.

    Ovikellon soitto katkaisee lukemisen. Pilvi juoksee ovelle ja avaa sen. Oven takana ei ole ketään. Ovatko naapuritalon kurittomat alakoululaiset tehneet jäynää?
    
    Hän sulkee oven ja huomaa taitetun paperin eteisen matolla. Pilvi poimii ja avaa sen. Teksti on sekavan näköistä, täynnä kirjoitusvirheitä, alleviivauksia, lihavointeja ja outoja merkintöjä.
    
    Teksti kertoo tinnituksesta. Pilvi tietää, että tinnitus liittyy korvan toimintaan ja juuri korvan osiahan hän oli hetki sitten ollut opettelemassa.
    
    Mutta miksi nuo kaikki lihavoidut kirjaimet ja alleviivaukset? Onko kirjeessä jokin salainen viesti?        
    `,
    instructions: `Lue kirje läpi ja selvitä, onko siinä jokin salainen viesti.
    
    Ratkaise piilosana ja kirjoita se tehtävän lopussa olevaan vastauskenttään, niin tarina voi jatkua.`,
    answer: `Rauhantie`,
};
