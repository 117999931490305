export default {
    header: `Mökki pellon takana`,
    introduction: `Pilvi tietää puiston lähellä olevan peltoalueen ja sen takana olevan mökin. Hän päättää lähteä kävelemään sitä kohti. Ulkoapäin mökki näyttää siltä, ettei siellä ole asuttu kymmeniin vuosiin. Ovi on kuitenkin raollaan ja sisältä kajastaa sinertävää valoa.

    Pienen tuvan keskellä olevalla pöydällä on läppäri, joka paljastuu sinisen valon lähteeksi. Pilvi astuu sisään ja samalla ovi pamahtaa kiinni. Pilvi kokeilee ovea: se on lukossa. Oven vieressä on koodilukko. Selviäisiköhän läppäristä avauskoodi?
    
    Pilvi nostaa läppärin syliinsä ja sen alta paljastuu kaupungin kartta. Karttaan on ympyröity kaupungin toisella puolella oleva rakennus. Onkohan se seuraava kohde, tuumii Pilvi.
    
    Nyt on kuitenkin tärkeintä löytää oven avauskoodi. Pilvi katsoo läppärin ruutua ja lukee siinä olevan tekstin.
    
    “Lue seuraava teksti tarkasti. Jos ymmärrät tehtävän, pääset eteenpäin. Jos et, …”
    
    Korva jaetaan kolmeen osaan: ulko-, väli- ja sisäkorvaan, joissa on pienempiä osia. Ulkokorva eli korvalehti vastaanottaa äänen, joka siirtyy korvakäytävään ja lopulta välikorvan tärykalvoon asti, jonka seurauksena tärykalvo alkaa värähdellä. Tämä värähtely saa vasara-, alasin- ja jalustinluiden luuketjun liikkumaan ja jalustinluun värähtelemään simpukan eteisikkunaa vasten. Sisäkorvassa simpukan aistinkarvat vastaanottavat ääniaistimuksen, josta se siirtyy kuulohermosäikeisiin eli kuuloradastoon, jossa tärkeät äänet suodatetaan hälystä.
    
    Näytölle oli ilmestynyt uusi tehtävä.
        
    Pilvi lukee tekstin kahteen kertaan ja miettii samalla lukittua ovea ja numerokoodia. Samalla läppäriin ilmestyy uusi tehtävä. Se käsittelee korvan anatomiaa. Juuri siitähän Pilvi oli aamulla lukenut biologian kirjasta.`,
    task: `Tehtävänä on laittaa korvan eri osat äänen etenemisen mukaiseen järjestykseen. Tämähän on helppoa, tuumaa Pilvi. Vai mitenkähän ne kuuloluut menivätkään? Löytyisikö tämän tehtävän kautta oven avauskoodi?

    `,
    instructions: `Auta Pilviä ratkaisemaan ongelma, niin tarina voi jatkua.

    Klikkaa kuvia äänen etenemisen mukaisessa järjestyksessä.
    
    Voit aloittaa uuden järjestyksen klikkaamalla Nollaa-painiketta.`,
};
