import { Link } from 'react-router-dom';

import PageWrapper from '../components/FirstPageWrapper';
import ContentWrapper from '../components/contentWrapper';
import StyledLink from '../components/link';
import Footer from '../components/footer';
import { Button } from '@mui/material';

import kuuloliittoLogo from '../res/kuuloliitto-logo.png';
import Kansi from '../res/pelinkansi.png';

function Home() {
    return (
        <PageWrapper
            style={{
                backgroundColor: '#000000',
            }}
        >
            <ContentWrapper
                style={{
                    backgroundImage: `url(${Kansi})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100%',
                }}
            ></ContentWrapper>
            <Footer>
                <div>
                <Link to={'/aloitus'}>
                    <Button
                        variant="contained"
                        style={{
                            margin: '10px 2% 10px 2%',
                            backgroundColor: '#ffe313',
                            fontSize: '30px',
                            color: '#000000',
                            display: 'inline-block',
                            alignSelf: 'center',
                        }}
                    >
                        Tästä pelaamaan
                    </Button>
                </Link>
                </div>
                <div>
                <StyledLink href="https://kuuloliitto.fi">
                    <img
                        src={kuuloliittoLogo}
                        style={{
                            width: '400px',
                            height: 'auto',
                            margin: '10px',
                            maxWidth: '100%',
                            display: 'inline-block',
                            alignSelf: 'center',
                        }}
                    />
                </StyledLink>
                </div>
            </Footer>
        </PageWrapper>
    );
}

export default Home;
