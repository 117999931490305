import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import GameCharacterImage from '../../res/hearingProtection_character.png';
import GameMapImage from '../../res/hearingProtection_map.png';
import gameMapRaw from '../../res/hearingProtection_map.json';
import PageWrapper from '../../components/PageWrapper';
import hearingProtection from '../../texts/hearingProtection';
import Title from '../../components/title';
import Text from '../../components/text';
import { Navigate, useNavigate } from 'react-router-dom';
const sound = require('../../res/audio/7_Salaperainen-kartta.mp3');

const AppContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledCanvas = styled.canvas`
    width: ${`canvasWidth`};
    height: ${`canvasHeight`};
    max-width: 75%;
    border: 1px solid black;
    margin-left: 20px;
    margin-right: 20px;
`;

const StyledControls = styled.div`
    button {
        margin: 20px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const gameMapImage = new Image();
gameMapImage.src = GameMapImage;

const gameCharacter = new Image();
gameCharacter.src = GameCharacterImage;

const characterOrigWidth = 416;
const characterOrigHeight = 454;
const characterScale = 0.5;
const characterWidth = characterOrigWidth * characterScale;
const characterHeight = characterOrigHeight * characterScale;

const mapWidth = 3840;
const mapHeight = (mapWidth * 9) / 16;
const mapScale = mapWidth / 3840;
const canvasWidth = 1920;
const canvasHeight = (canvasWidth * 9) / 16;
const loseNodes = [7, 8, 12, 14, 26, 33, 40, 51, undefined];

const gameMap = gameMapRaw.map((node) => ({
    position: {
        x: node.position.x * mapScale,
        y: node.position.y * mapScale,
    },
    up: node.up
        ? gameMapRaw.findIndex((foundNode) => foundNode.id === node.up)
        : undefined,
    down: node.down
        ? gameMapRaw.findIndex((foundNode) => foundNode.id === node.down)
        : undefined,
    left: node.left
        ? gameMapRaw.findIndex((foundNode) => foundNode.id === node.left)
        : undefined,
    right: node.right
        ? gameMapRaw.findIndex((foundNode) => foundNode.id === node.right)
        : undefined,
}));

function HearingProtection() {
    const navigate = useNavigate();

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [currentNodeIndex, setCurrentNodeIndex] = React.useState(0);
    const [isDead, setIsDead] = React.useState(false);
    const [deathAmount, setDeathAmount] = React.useState(0);

    const buttonPressCallback = (direction: string) => {
        switch (direction) {
            case 'up':
                if (gameMap[currentNodeIndex].up !== undefined && !isDead)
                    if (loseNodes.includes(gameMap[currentNodeIndex].up)) {
                        setCurrentNodeIndex(gameMap[currentNodeIndex].up!);
                        handleDeath();
                    } else {
                        setCurrentNodeIndex(gameMap[currentNodeIndex].up!);
                    }
                break;
            case 'down':
                if (gameMap[currentNodeIndex].down !== undefined && !isDead)
                    if (loseNodes.includes(gameMap[currentNodeIndex].down)) {
                        setCurrentNodeIndex(gameMap[currentNodeIndex].down!);
                        handleDeath();
                    } else {
                        setCurrentNodeIndex(gameMap[currentNodeIndex].down!);
                    }
                break;
            case 'left':
                if (gameMap[currentNodeIndex].left !== undefined && !isDead)
                    if (loseNodes.includes(gameMap[currentNodeIndex].left)) {
                        setCurrentNodeIndex(gameMap[currentNodeIndex].left!);
                        handleDeath();
                    } else {
                        setCurrentNodeIndex(gameMap[currentNodeIndex].left!);
                    }
                break;
            case 'right':
                if (gameMap[currentNodeIndex].right !== undefined && !isDead)
                    if (loseNodes.includes(gameMap[currentNodeIndex].right)) {
                        setCurrentNodeIndex(gameMap[currentNodeIndex].right!);
                        handleDeath();
                    } else {
                        setCurrentNodeIndex(gameMap[currentNodeIndex].right!);
                    }
                break;
        }
    };
    
    const handleDeath = () => {
        setTimeout(() => setCurrentNodeIndex(0), 1000);
        setTimeout(() => setIsDead(false), 1000);
        setIsDead(true);
        setDeathAmount(deathAmount + 1);
        if (deathAmount >= 4) {
            alert("Astuit meluisalle alueelle viidesti, siirryt seuraavaan tehtävään.");
            navigate("/vaittamia-kuulosta")
        }

    }

    const redrawCanvas = () => {
        const canvas = canvasRef.current;

        if (canvas === null) return;

        const ctx = canvas.getContext('2d')!;

        ctx.drawImage(gameMapImage, 0, 0, mapWidth, mapHeight);
        ctx.fillStyle = '#000000';

        ctx.drawImage(
            gameCharacter,
            gameMap[currentNodeIndex].position.x - characterWidth / 2,
            gameMap[currentNodeIndex].position.y - characterHeight,
            characterWidth,
            characterHeight
        );
    };

    gameMapImage.onload = redrawCanvas;
    gameCharacter.onload = redrawCanvas;

    useEffect(redrawCanvas, [canvasRef.current, currentNodeIndex]);

    return (
        <PageWrapper
            nextPageURL="/vaittamia-kuulosta"
            deathMessage='Jatketaan seuraavaan tehtävään.'
            canContinue={() => currentNodeIndex === 49}
        >
            <AppContainer>
                <ContentWrapper>
                    <audio src={sound} controls></audio>
                    <Title>{hearingProtection.header}</Title>
                    <Text>{hearingProtection.introduction}</Text>
                    <Text>{hearingProtection.instruction}</Text>
                </ContentWrapper>
                <StyledCanvas
                    ref={canvasRef}
                    width={mapWidth}
                    height={mapHeight}
                />
                <StyledControls>
                    <button
                        onClick={() => buttonPressCallback('up')}
                        {...{
                            disabled:
                                gameMap[currentNodeIndex].up === undefined
                                    ? true
                                    : false,
                        }}
                    >
                        Ylös
                    </button>
                    <button
                        onClick={() => buttonPressCallback('down')}
                        {...{
                            disabled:
                                gameMap[currentNodeIndex].down === undefined
                                    ? true
                                    : false,
                        }}
                    >
                        Alas
                    </button>
                    <button
                        onClick={() => buttonPressCallback('left')}
                        {...{
                            disabled:
                                gameMap[currentNodeIndex].left === undefined
                                    ? true
                                    : false,
                        }}
                    >
                        Vasen
                    </button>
                    <button
                        onClick={() => buttonPressCallback('right')}
                        {...{
                            disabled:
                                gameMap[currentNodeIndex].right === undefined
                                    ? true
                                    : false,
                        }}
                    >
                        Oikea
                    </button>
                </StyledControls>
            </AppContainer>
        </PageWrapper>
    );
}

export default HearingProtection;
