import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    text-align: center;
    overflow-y: auto;
    background-color: #ebf3ff;

`;