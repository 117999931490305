export default {
    header: `Hiljainen puisto`,
    introduction: `Kirjeessä olleen tehtävän ratkaisuna on osoite, joka viittaa lähellä olevaan puistoon. Pilvi sulkee biologian kirjansa ja päättää lähteä katsomaan, mitä puistosta löytyisi.

    Puisto on pieni keidas kaupungin laidalla. Siellä on muutama penkki sekä isoja, vanhoja tammia. Puiston hiljaisuudessa Pilvi kuulee hentoa säksätystä. Ison tammen oksalla istuu orava tammenterhoa nakertaen.
    
    Pilvin katsellessa oravan touhuja hän huomaa oksan tyvessä jotain sinistä. Pilvi lähestyy puuta varoen häiritsemästä oravaa. Esine osoittautuu sinikantiseksi kouluvihoksi.
    
    Vihkon välistä putoaa paperi. Se on samanlaista, vanhaa kellastunutta paperia kuin Pilvin aiemmin saama kirje. Teksti on nuhraantunut.
    
    Pilville tulee mieleen edellisen viikon ulkoilmakonsertti keskuspuistossa. Ohiajava liikenne oli häirinnyt musiikin kuuntelua eikä Sannan puheestakaan ollut saanut mitään selvää. Melu peitti puhetta samaan tapaan kuin tämän paperin tahrat haittasivat lukemista.
    
    Onko tässäkin paperissa joku vihje?
    
    Pilvi istahtaa puiston penkille ja ryhtyy tutkimaan, minkälaisia vihjeitä paperi antaa.
    `,
    instructions: `Lue kirje läpi, ratkaise piilosana ja kirjoita se lopussa olevaan vastauskenttään.`,
    answer: `maatila`,
};
