import styled from 'styled-components';
import Kansi from '../res/pelinkansi.png';

export default styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    text-align: left;
    overflow-y: auto;
    background-size: 100%;
    flex-grow: 1;
`;
