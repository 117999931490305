import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Intro from './pages/intro';
import Home from './pages/home';
import HiddenWord from './pages/games/hiddenWord';
import Listening from './pages/games/listening';
import EarAnatomy from './pages/games/earAnatomy';
import DeadSensorhairs from './pages/games/deadSensorhairs';
import HearingClaims from './pages/games/hearingClaims';
import HearingProtection from './pages/games/hearingProtection';
import NoiseVideos from './pages/games/noiseVideos';
import Ending from './pages/games/ending';
import InfoPage from './pages/info'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Intro />} />
                <Route path="/aloitus" element={<Home />} />
                <Route path="/piilosana" element={<HiddenWord />} />
                <Route path="/kuullunymmartaminen" element={<Listening />} />
                <Route path="/korvan-anatomia" element={<EarAnatomy />} />
                <Route path="/meluvideot" element={<NoiseVideos />} />
                <Route
                    path="/kuolleet-aistinkarvat"
                    element={<DeadSensorhairs />}
                />
                <Route
                    path="/kuulon-suojaaminen"
                    element={<HearingProtection />}
                />
                <Route path="/vaittamia-kuulosta" element={<HearingClaims />} />
                <Route path="/lopetus" element={<Ending />} />
                <Route path="/info" element={<InfoPage />} />
            </Routes>
        </Router>
    );
}

export default App;
