import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import PageWrapper from '../../components/endingWrapper';
import ContentWrapper from '../../components/contentWrapper';
import Title from '../../components/title';
import Text from '../../components/text';
import StyledLink from '../../components/link';
import Footer from '../../components/footer';

import kuuloliittoLogo from '../../res/kuuloliitto-logo.png';
import ilotulitteet from '../../res/fireworks.png';

import home from '../../texts/ending';

const sound = require('../../res/audio/9_Koe-suoritettu.mp3');

function Ending() {
    return (
        <PageWrapper>
            <ContentWrapper
                style={{
                    backgroundImage: `url(${ilotulitteet})`,
                }}
            >
                <audio src={sound} controls></audio>
                <Title>{home.header}</Title>
                <Text style={{ fontWeight: 500 }}>{home.introduction}</Text>
                <Link to={'/info'}>
                    <Button variant="contained" style={{ margin: '10px' }}>
                        Lopeta
                    </Button>
                </Link>
            </ContentWrapper>
            <Footer>
                <StyledLink href="https://kuuloliitto.fi">
                    <img
                        src={kuuloliittoLogo}
                        style={{
                            width: '500px',
                            height: 'auto',
                            margin: '10px',
                            maxWidth: '100%',
                        }}
                    />
                </StyledLink>
            </Footer>
        </PageWrapper>
    );
}

export default Ending;
