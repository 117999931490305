import { Link } from 'react-router-dom';

import PageWrapper from '../components/FirstPageWrapper';
import ContentWrapper from '../components/contentWrapper';
import Title from '../components/title';
import Footer from '../components/footer';
import Text from '../components/text';
import { Button } from '@mui/material';

import info from '../texts/info';

const sound = require('../res/audio/1_Melumysteeri.mp3');
//<Title>{info.header}</Title>

function InfoPage() {
    return (
        <PageWrapper>
            <ContentWrapper>
                <Text>{info.intorduction}</Text>
            </ContentWrapper>
            <Footer>
                <Link to={'/'}>
                    <Button
                        variant="contained"
                        style={{
                            margin: '10px 2% 10px 2%',
                            backgroundColor: '#ffe313',
                            color: '#000000',
                        }}
                    >
                        Aloitussivulle
                    </Button>
                </Link>
            </Footer>
        </PageWrapper>
    );
}

export default InfoPage;
