import { useState } from 'react';
import { TextField } from '@mui/material';

import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/title';
import Text from '../../components/text';

import exercise1 from '../../res/tehtava1.png';

import hiddenWord from '../../texts/hiddenWord';
import styled from 'styled-components';

const sound = require('../../res/audio/2_Mystinen-kirje.mp3');

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;

    text-align: center;
    overflow-y: auto;
`;

function Piilosana() {
    const [answer, setAnswer] = useState<string>('');
    return (
        <PageWrapper
            nextPageURL="/kuullunymmartaminen"
            deathMessage="Rauhantie"
            canContinue={() =>
                answer.toLowerCase() === hiddenWord.answer.toLowerCase()
            }
        >
            <ContentWrapper>
                <audio src={sound} controls></audio>
                <Title>{hiddenWord.header}</Title>
                <Text>{hiddenWord.introduction}</Text>
                <img
                    style={{ width: '95%', margin: '20px 2% 20px 2%' }}
                    src={exercise1}
                ></img>
                <Text> {hiddenWord.instructions}</Text>
                <TextField
                    InputProps={{
                        style: { backgroundColor: 'white', color: 'black' },
                    }}
                    value={answer}
                    onChange={(e) => {
                        setAnswer(e.target.value);
                    }}
                    variant="outlined"
                    label="Vastaus"
                    style={{
                        margin: '20px 2% 20px 2%',
                    }}
                />
            </ContentWrapper>
        </PageWrapper>
    );
}

export default Piilosana;
