import { useState } from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import PageWrapper from '../../components/PageWrapper';
import Title from '../../components/title';
import Text from '../../components/text';
import noisevideos from '../../texts/noisevideos';
const sound = require('../../res/audio/5_Meluvideot-jarjestykseen.mp3');

const VideosContainer = styled.div`
    display: flex;
    margin: 20px;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    gap: 30px;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    height: 350px;
`;

const VideoLetter = styled.h1`
    color: white;
`;

const TextFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ;
    justify-content: space-around;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
`;

const NoiseVideos = () => {
    const videos = ['EOGhS7UASDA', '3tj7m7K9fis', 'VbhyomKgXMU', 'YSRthPSp530'];
    const correctOrder = 'CABD';
    const videoNames = ['A) Keskustelu', 'B) Konsertti', 'C) Sade', 'D) Hornetit '];

    const [input, setInput] = useState<string>('');

    return (
        <PageWrapper
            nextPageURL="/kuolleet-aistinkarvat"
            deathMessage="CABD"
            canContinue={() => input === correctOrder}
        >
            <TextContainer>
                <audio src={sound} controls></audio>
                <Title>{noisevideos.header}</Title>
                <Text>{noisevideos.introduction}</Text>
                <Text>{noisevideos.instruction}</Text>
            </TextContainer>
            <VideosContainer>
                {videos.map((video, index) => (
                    <VideoContainer key={video}>
                        <VideoLetter>{videoNames[index]}</VideoLetter>
                        <YouTube
                            videoId={video}
                            opts={{ width: '100%', height: '177%' }}
                        />
                    </VideoContainer>
                ))}
            </VideosContainer>

            <TextFieldContainer>
                <TextField
                    value={input}
                    onChange={(e) => {
                        setInput(e.target.value.toUpperCase());
                    }}
                    label="Vastaus"
                    InputProps={{
                        style: { backgroundColor: 'white', color: 'black' },
                    }}
                    style={{
                        margin: '20px 2% 20px 2%',
                    }}
                />
            </TextFieldContainer>
        </PageWrapper>
    );
};

export default NoiseVideos;
