import styled from 'styled-components';

const Text = styled.p`
    margin: 20px;
    margin-left: 2%;
    margin-right: 15%;
    white-space: pre-line;
    text-align: left;
    font-size: 1.7em;
    color: white;
    font-weight: 300;
`;

export default Text;
