import { Button } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tausta from '../../src/res/kuvitus2.png';

interface PageWrapperProps {
    nextPageURL: string;
    deathMessage?: string;
    children: ReactNode;
    canContinue?: () => boolean;
}

const ContentWrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #ebf3ff;
`;

const ChildrenWrapper = styled.div`
    width: 100%;
    background-image: url(${tausta});
    background-size: 100%;
`;

const FooterContainer = styled.div`
    width: 100%;
    height: 120px;
    border-top: 10px solid #4287f5;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #f9f9fb;
`;

const PageWrapper = (props: PageWrapperProps) => {
    const [tries, setTries] = useState(0);

    const navigate = useNavigate();
    const nextButtonClicked = () => {
        if (
            props.nextPageURL !== '/lopetus'
        ) {
            setTries(tries + 1);
        }
        if (!props.canContinue || (props.canContinue && props.canContinue())) {
            window.scrollTo(0, 0);
            alert(
                'Oikea vastaus!'
            )
            navigate(props.nextPageURL);
        } else if (tries > 4) {
            if (props.nextPageURL !== '/vaittamia-kuulosta') {
                alert(
                    'Vastasit väärin viidesti, siirryt seuraavaan tehtävään.\n\n' +
                        'Oikea vastaus: ' +
                        props.deathMessage
                )}
            else {
                alert(props.deathMessage);
            }
            window.scrollTo(0, 0);
            navigate(props.nextPageURL);
        } else if (props.nextPageURL == '/kuullunymmartaminen' && tries > 2) {
            alert('Vinkki: vastaus on osoite.');
        } else if (props.nextPageURL == '/lopetus') {
            alert('Oikeat vastaukset pysyvät tehtävässä. Vastaa muihin kohtiin uudelleen.');
        } else {
            alert('Väärä vastaus, yritä uudelleen.');
        } 
    };

    return (
        <ContentWrapper>
            <ChildrenWrapper>{props.children}</ChildrenWrapper>
            <FooterContainer>
                <Button
                    sx={{ padding: '12px' }}
                    onClick={nextButtonClicked}
                    variant="outlined"
                    style={{ margin: '10px 2% 10px 2%' }}
                    id="next"
                >
                    Jatka
                </Button>
            </FooterContainer>
        </ContentWrapper>
    );
};

export default PageWrapper;
